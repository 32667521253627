'use strict';

// Imports.
import { chatService } from '../../services';

// Specified actions for mutating the alert state.
export default {
  // Use the mint service to get a list of available shop items.
  async getMessages({ dispatch, commit }, { tokenAddress, tokenId }) {
    try {
      let assets = await seaportService.getAsset( tokenAddress, tokenId );
      console.log("getAsset", assets)
      commit('updateAssets', assets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async login({ dispatch, commit }, { userAddress, room }) {
    try {
      console.log("trying to log in...")
      commit('clearHistory', "");
      let socket = await chatService.login( userAddress, room, dispatch, commit );

      commit('updateSocket', socket);

      let listenerWelcome = `You are now listening to transactions from ${room}`;
      let welcome = {"name": "sys", "message": listenerWelcome, "type": "advisory", "timestamp": new Date()}
      console.log("listenerWelcome", listenerWelcome)
      commit('updateMessages', welcome);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async pushMessage({ dispatch, commit }, { message }) {
    try {
      commit('updateMessages', message);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }

};

'use strict';

// Specified state transitions per particular actions.
export default {

  // Update the list of launchpad items being tracked for display purposes.
  updateUserAddress(state, userAddress) {
    state.userAddress = userAddress;
  },

  updateMessages(state, message) {
    console.log("message", message)
    state.messages.push(message);
  },

  clearHistory(state, message) {
    state.messages = [];
    state.txs = [];
  },

  updateSocket(state, socket) {
    state.socket = socket;
  },

  updateTxs(state, tx) {
    state.txs.push(tx);
  },
};

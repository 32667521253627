<template>
    <div>
      <div style="text-align:left;">
        <div class="messageRow">
          <div class="msgtime">
            ({{new Date(message.timestamp).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")}})
          </div>
          <div v-if="message.owner" class="senderWrap" style="color:#ff0" >
           <strong>{{message.name}}:</strong>
          </div>
          <div v-else-if="message.self" class="senderWrap" style="color:#0f0" >
           <strong>{{message.name}}:</strong>
          </div>
          <div v-else class="senderWrap" style="color:#0dd">
           {{message.name}}:
          </div>
          <div v-if="message.self" class="messageWrap">
           <strong>{{message.message}}</strong>
          </div>
          <div v-else class="messageWrap" style="color:#ccc">
           {{message.message}}
          </div>
        </div>
      </div>
    </div>

</template>
<script>

import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
import Ethereum from '/src/components/common/Ethereum.vue';

export default {

  props: {
    msg: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const store = useStore();

    const message = computed(() => {
      let formatted = props.msg;
      if(formatted.name.length == 42 ){
        console.log(formatted.name)
        formatted.name = formatted.name.substring(0, 10)
      }


      return formatted;
    });

    return {
      store,
      message
    };
  }
};
</script>
<style scoped lang="scss">

.messageRow {
  margin:2px 2px 2px 2px;
}

.msgtime {
  display:inline-block;
  margin:2px;
  color:#555;
  font-family:monospace;
}

.senderWrap {
  font-family:monospace;
  display: inline-block;
  //font-size:.7em;
  color:#0FF;
  margin:2px;
}

.messageWrap {
  font-family:monospace;
  display: inline-block;
  padding:0px 5px;

  //background:#222;
  //border: solid 1px #222;
  //border-radius:5px;
  margin-bottom:3px;
}

.selfSenderWrap {
  text-align:right;
  font-size:.7em;
  margin:2px;
}

.selfMessageWrap {
  font-family:monospace;
  display: inline-block;
  padding:5px 15px;

  background:#013636;
  border: solid 1px #013636;
  //border-radius:5px;
  text-align:right;
  margin-bottom:3px;
}
</style>

<template>
    <div>
      <div v-if="message.owner" style="text-align:left;">
        <div class="messageRow" style="clear:both">
          <div class="senderWrap" v-show="message.senderSuccession == false">
           {{message.name}}
          </div>
          <div class="selfMessageWrap" style="background:#ff0; color:#333;">
           {{message.message}}
          </div>
        </div>
      </div>

      <div v-else-if="message.self" style="text-align:right;" >
        <div class="selfSenderWrap" v-show="!message.senderSuccession">
         {{message.name}}
        </div>
        <div class="selfMessageWrap">
         {{message.message}}
        </div>
      </div>
      <div v-else-if="message.type" style="text-align:left;">
        <div class="messageRow" style="clear:both">
          <div class="senderWrap" v-show="message.senderSuccession == false">
           {{message.name}}
          </div>
          <div class="selfMessageWrap">
           {{message.message}}
          </div>
        </div>
      </div>

      <div v-else class="messageRow" style="clear:both">
        <div class="senderWrap" style="margin-top:10px" v-show="!message.senderSuccession">
         {{message.senderSuccession}}
         {{message.name}}
        </div>
        <div class="messageWrap">
         {{message.message}}
        </div>
      </div>
    </div>

</template>
<script>

import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
import Ethereum from '/src/components/common/Ethereum.vue';

export default {

  props: {
    msg: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const message = ref(props.msg);

    onMounted(async () => {
      if(message.value.name.length == 42 ){
        console.log(props.msg.name)
        message.value.name = message.value.name.substring(0, 10)
        //console.log("provider", await store.state.ethers.provider.lookupAddress(props.msg.name))
        // let provider = store.state.ethers.provider;
        // let name = await provider.lookupAddress(props.msg.name);
        // console.log("name", name)
        // if(!name){
        //   message.value.name = message.value.name.substring(0, 10)
        //   console.log("message.value.name", message.value.name)
        //   // record.name = (ensName) ? ensName : record.name;
        // }
      }

    });

    return {
      message
    };
  }
};
</script>
<style scoped lang="scss">

.messageRow {
  margin:2px 2px 2px 2px;
}

.senderWrap {
  font-size:.7em;
  margin:2px;
}

.messageWrap {
  font-family:monospace;
  display: inline-block;
  padding:5px 15px;

  background:#222;
  border: solid 1px #222;
  //border-radius:5px;
  margin-bottom:3px;
}

.selfSenderWrap {
  text-align:right;
  font-size:.7em;
  margin:2px;
}

.selfMessageWrap {
  font-family:monospace;
  display: inline-block;
  padding:5px 15px;

  background:#013636;
  border: solid 1px #013636;
  //border-radius:5px;
  text-align:right;
  margin-bottom:3px;
}
</style>

<template>
  <Ethereum :callback="initialize" />
  <div class="claim">
    <div class="container" v-if="store.state.ethers.address">
      <div class="topWrapper">
        <div class="executeButton">
          <TextInput style="padding:4px 12px; width: 51%" v-model="location" placeholder="Enter an ethereum address" class="textInput" />

          <Button @click="login" style="width: 100px;margin-left:10px; padding:5px; border-radius:1px;background:rgb(0, 255, 255)"> join </Button>
        </div>

      </div>
      <div class="txHistory" v-if="messages.length" >
        <div style="color:#0FF">Transactions ({{txHistory.length}})</div>

        <!--
        <div>
          <Button @click="stopListeners" style="height:40px;margin:5px 50px 5px 0; padding:5px; border-radius:1px;background:rgb(0, 255, 255)"> stop </Button>
          <Button @click="checkListeners" style="height:40px;margin:5px 50px 5px 0; padding:5px; border-radius:1px;background:rgb(0, 255, 255)"> chk </Button>
          <Button @click="restartListeners" style="height:40px;margin-top:50px; padding:5px; border-radius:1px;background:rgb(0, 255, 255)"> restart </Button>
        </div>
      -->
        <div v-for="tx in txHistory" :key="tx">
          <a :href="tx.link" style="color:#0FF" target="_blank" class="txlink">
             {{tx.label}}
          </a>
        </div>
      </div>

      <div v-if="nostalgic" class="chatHistory" style="margin-top:5px" >
        <NostalgicMessage
          v-for="message in messages"
          :msg="message"
          :key="message.timestamp"
        />
      </div>
      <div v-else class="chatHistory" style="margin-top:5px" >
        <Message
          v-for="message in messages"
          :msg="message"
          :key="message.timestamp"
        />
      </div>
      <div v-if="enteredRoom" class="chatControls">
        <Button @click="toggleNostalgic" v-if="nostalgic" style="float:right; height:30px; padding:0 10px; border-radius:1px;background:rgb(0, 255, 255); font-size:.8em">+</Button>
        <Button @click="toggleNostalgic" v-if="!nostalgic" style="float:right; height:30px; padding:0 10px; color: rgb(0, 255, 255);border-radius:1px;background: rgb(16,16,16); border: 1px solid #0FF; font-size:.8em">-</Button>
        <div style="">Nostalgia</div>
      </div>
      <div v-if="isOwner" class="adminControls" >
        Admin
        <Button @click="toggleNostalgic" style="width: 40px; height:30px; padding:0px; border-radius:1px;background:rgb(0, 255, 255)"> b</Button>

      </div>
      <div class="roster" v-if="messages.length">
        <span style="color:#0FF">Fellow Apes ({{roster.length}})</span>
        <div v-for="member in roster" :key="member" >
          {{ member }}
        </div>
      </div>

      <div v-if="enteredRoom" class="burnButtonWrapper">
        <TextInput style="padding:4px 12px; width:83%" v-model="messageText" placeholder="Enter a message" class="textInput" />
        <Button @click="sendMessage" style="width: 10%; height:40px; padding:5px; border-radius:1px;background:rgb(0, 255, 255)"> → </Button>
      </div>
    </div>

    <ConnectWallet v-else />
  </div>
</template>
<script>
'use strict';

// Imports.
import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { ethers } from 'ethers';
import Ethereum from '/src/components/common/Ethereum.vue';

// Component Imports.
import Button from '/src/components/ui/Button.vue';
import TextInput from '/src/components/ui/TextInput.vue';
//import BaseCheckbox from '/src/components/ui/BaseCheckbox.vue';
import Message from '/src/pages/home/components/Message.vue';
import NostalgicMessage from '/src/pages/home/components/NostalgicMessage.vue';
//import NFTRow from '/src/pages/import/components/NFTRow.vue';
//import LoadingSpinnerIcon from '/src/components/icons/LoadingSpinnerIcon.vue';
import { BigNumber } from '@ethersproject/bignumber';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';
import axios from 'axios';

export default {
  components: {
    Button,
    TextInput,
    //NFTRow,
    Ethereum,
    ConnectWallet,
    Message,
    NostalgicMessage,
    //LoadingSpinnerIcon,
    //BaseCheckbox
  },

  setup(props, context) {
    const location = ref('');
    const currentRoom = ref('');
    const enteredRoom = ref(false);

    const analyzing = ref(false);
    const finished = ref(false);
    const store = useStore();
    const router = useRouter();

    const socket = ref({});
    const loading = ref(false);
    const nostalgic = ref(false);
    const messageText = ref('');

    const isOwner = computed(() => {
        console.log('owner check', store.state.ethers.address, currentRoom.value)
        return store.state.ethers.address.toLowerCase() == currentRoom.value.toLowerCase()
    });

    const messages = computed(() => {
      let msgHistory = []
      let lastSender = '' ;
      for(let record of store.state.chat.messages){

        if(record.name && record.message){
          if(record.name.length == 42){
            if(record.name == store.state.ethers.address){
              record.self = true;
            }

            if(record.name.toLowerCase() == currentRoom.value.toLowerCase()){
              record.owner = true;
            }
            //let ensName = await store.state.ethers.provider.lookupAddress(record.joined);
            // let ensName = record.name.substring(0, 10)
            // record.name = (ensName) ? ensName : record.name;
          }
          if(lastSender == record.name){
            record.senderSuccession = true;
          }

          msgHistory.push(record);
        }
        lastSender = record.name;
      }
      return msgHistory;
    });

    const txHistory = computed(() => {
      let txs = [];
      for(let tx of store.state.chat.txs){
        if(!txs.includes(tx)){
          txs.unshift({"link": `https://etherscan.io/tx/${tx}`, "label": `${tx.substring(0, 18)}...`});
        }
      }
      return txs;
    });

    const roster = computed(() => {
      let members = []
      for(let record of store.state.chat.messages){
        if(record.joined ){
          let username = record.joined;
          if(record.joined.length == 42){
            //let ensName = await store.state.ethers.provider.lookupAddress(record.joined);
            let ensName = record.joined.substring(0, 10)
            username = (ensName) ? ensName : record.joined;

          }

          if(record.joined.toLowerCase() == currentRoom.value.toLowerCase()){
            record.owner = true;
            username = username + ' *'
          }
          if(!members.includes(username)){
            members.push(username);
          }
        }
        if(record.quit){
          let memberIndex = members.indexOf(record.quit);
          members.splice(memberIndex, 1);
        }
      }
      return members;
    });

    const toggleNostalgic = async () => {
      nostalgic.value = !nostalgic.value;
      window.scroll(0, 100000);
    }

    watch((location) => {
      window.scroll(0, 10000);
    });

    watch(
      () => messages.value,
      () => {
        window.scroll(0, 10000);
      }
    )

    const initialize = async () => {
      // if (store.state.ethers.canSign) {
      //
      // }
    };

    const checkListeners = async () => {
      let lcount = await store.state.ethers.provider.listeners()
      console.log('listener check', lcount)
    }

    const stopListeners = async () => {
      await store.state.ethers.provider.removeAllListeners()
      console.log('listener stopped')
    }

    const restartListeners = async () => {
      let filter = {
        address: location.value, //"dai.tokens.ethers.eth",
        topics: [
            null//utils.id("Transfer(address,address,uint256)")
        ]
      }
      store.state.ethers.provider.on(filter, (log, event) => {
          let txmsg = {"name": "sys", "message": `https://etherscan.io/tx/${log.transactionHash}`, "type": "tx", "timestamp": new Date()}
          messages.value.push(txmsg)
      })
      console.log('listener restarted', location.value)
    }

    const login = async () => {
      await store.dispatch(
        'chat/login',
        {
          userAddress: store.state.ethers.address,
          room: location.value
        },
        { root: true }
      );
      currentRoom.value = location.value;
      enteredRoom.value = true;
      window.scroll(0, 10000);
    };


    const sendMessage = async () => {
      if(store.state.chat.socket){
        store.state.chat.socket.send(JSON.stringify({message: messageText.value}))
        messageText.value = '';
        window.scroll(0, 1000);
      }
    }

    const closeSocket = async () => {
      await store.dispatch(
        'seaport/closeSocket',
        { socket: socket.value },
        { root: true }
      );
    }

    const page = computed(() => {
      return router.currentRoute.value.query.p ? router.currentRoute.value.query.p : 0;
    });


    return {
      analyzing,
      finished,

      location,
      enteredRoom,
      page,
      store,
      initialize,
      loading,
      closeSocket,
      sendMessage,
      login,
      stopListeners,
      restartListeners,
      checkListeners,
      messageText,
      messages,
      roster,
      txHistory,
      nostalgic,
      toggleNostalgic,
      isOwner
    };
  }
};
</script>
<style scoped lang="scss">


.txMessage {
  font-family:monospace;
  padding:15px;
  margin:2px 2px 22px 2px;
  background:#222;
  border: solid 1px #0ff;
  border-radius:2px;
}

.item {
  display: block;
}

.textInput {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 51%;
  display: inline-block;
}

.executeButton {
  display: inline-block;
  width:100%;
}

.chatHistory {
  background: #111;
  padding:0px 15px;
  margin-top:100px;
  margin-bottom:30px;
  margin-left:20%;
  display:inline-block;
  width: 60%;
}

.txHistory {
  margin-left:80px;
  vertical-align:top;
  top:100px;
  display:inline-block;
  width:15%;
  max-height:60%;
  overflow-y:auto;
}

.roster, .txHistory {
  top:85px;
}

.roster, .txHistory, .chatControls, .adminControls {
  font-family:monospace;
  position:fixed;
  display:inline-block;
  width:15%;
  padding:5px 15px;
  margin:2px 22px 22px 20px;
  background:#222;
  border: solid 1px #0ff;
  border-radius:2px;
}

.chatControls {
  bottom:37px;
}

.adminControls {

  top:25px;
}

.claim {
  padding: 10px 20px;

  .container {
    max-width: 1800px;
    margin-right: auto;
    margin-left: auto;

    .resultsTable {
      margin-bottom: 20px;
      border-style: solid;
      border-width: 1px;
      border-color: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      background: rgba(0,0,0,.8);

      .headerWrapper {
        display: grid;
        padding: 10px;
        grid-auto-columns: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        grid-template-columns: 50px 80px minmax(200px, 1fr) 100px 150px 150px 250px 250px;
        grid-template-rows: auto;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        font-weight: 600;
        align-items: center;

        .title {
          padding: 10px;
          cursor: pointer;
        }
      }
    }

    .bottomWrapper {
      align-items: center;
      text-align: center;
    }

    .topWrapper {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: fixed;
      width:100%;
      //margin-top:-140px;
      top:25px;
      margin-bottom:-40px;
      //margin-left:180px;

      .heading {
        margin-top: 0px;
        margin-bottom: 20px;
        color: #ffd915;
        font-size: 60px;
        line-height: 1em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 10px;
      }

      .claimChecker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .label {
          white-space: nowrap;
          margin-right: 10px;
        }
      }

      .status {
        margin-top: 10px;
        padding: 10px 20px;
        background-color: #15ff6f;
        color: #000;
        font-weight: 700;
      }

      .claimed {
        background-color: #ff1e1e;
        color: #fff;
      }
    }

    .itemsGrid {
      //display: grid;
      width: 100%;
      justify-content: center;
      justify-items: center;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(300px, min-content));
      grid-template-rows: 1fr;

      .selected {
        box-shadow: 0 0 15px 2px #fb3700;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .burnButtonWrapper {
      background:black;
      position: sticky;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 5;
      display: block;
      min-height: 40px;
      width:64%;
      margin:auto;
      //margin-bottom: 40px;
      text-align:right;
      padding: 40px 15px 40px 0px;
      justify-content: center;
      justify-items: center;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: auto;
      grid-template-rows: auto;
      //background-image: linear-gradient(0deg, #000 50%, transparent);
    }
  }
}
</style>

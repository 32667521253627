'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
//import { createClient } from 'redis';

let config;
(async () => {
  config = await initializeConfig();
})();


const login = async function(userAddress, room, dispatch, commit) {
  //let url =
  console.log("opening websocket", userAddress, room, commit)
  let provider = await ethersService.getProvider();
  // if(userAddress.length == 42){
  //
  //   let ensName = await provider.lookupAddress(userAddress);
  //   userAddress = (ensName) ? ensName.substring(0, ensName.length - 4) : userAddress;
  // }
  if(room.length == 42 && ethers.utils.isAddress(room)){

    let filter = {
      address: room, //"dai.tokens.ethers.eth",
      topics: [
          null//utils.id("Transfer(address,address,uint256)")
      ]
    }
    provider.on(filter, (log, event) => {
        // Emitted whenever a DAI token transfer occurs
        console.log("event", log, event)
        let txmsg = {"name": "sys", "message": `https://etherscan.io/tx/${log.transactionHash}`, "type": "tx", "timestamp": new Date()}
        //commit('updateMessages', txmsg)
        commit('updateTxs', log.transactionHash)
        dispatch('alert/clear', '', { root: true });
        dispatch(
          'alert/info',
          {
            message: "New tx found",
            duration: 3000
          },
          { root: true }
        );
    })

  }
  //if(!websocket){
  //let url = 'wss://forest.throw.workers.dev/ws';
  let hostname = 'chat.superfarm.workers.dev';
  //hostname = 'chat-staging.superfarm.workers.dev'
  //let roomname = 'f';
  let websocket = new WebSocket("wss://" + hostname + "/api/room/" + room.toLowerCase() + "/websocket");
  if (!websocket) {
    throw new Error("Server didn't accept WebSocket")
  }

  websocket.onopen = function () {
    console.log("opened", websocket)
    websocket.send(JSON.stringify({name: userAddress}));
    websocket.onmessage = function(event) {
      //alert(`[message] Data received from server: ${event.data}`);
      console.log(`[message] Data received from server: ${event.data}`)
      let data = JSON.parse(event.data)

      commit('updateMessages', data)
    };
  }

  websocket.onclose = function() {
    console.log('Closed websocket')
  }

  websocket.onerror = function(error) {
    console.log("error", error)
  }

  return websocket;
}

const sendMessage = async (sock, message) => {
  console.log("sock", sock)
  let send = await sock.send(JSON.stringify(message));
  console.log("send message", send)
}

// Close WebSocket connection at a later point
const closeConnection = async function(sock) {
  console.log("connection closed", sock);
  await sock.close();
}

const lookupEnsName = async function(userAddress) {
  let provider = await ethersService.getProvider();
  let ensName = await provider.lookupAddress(userAddress);
  return ensName;
}


// const subscribe = async function( property, event ) {
//   client.subscribe( property, function(err, property) {
//     console.log('[REDIS]', `subscribed to ${property}`);
//   });
//   client.on(event, (property, event) => {
//     console.log(`[REDIS] Received message from ${property} channel: ${event}`);
//   });
// };


export const chatService = {
  // get,
  // set,
  // publish,
  // subscribe

  login,
  closeConnection,
  sendMessage,
  lookupEnsName
};
